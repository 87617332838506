/* eslint-disable camelcase */
/* eslint-disable no-console */
import { mapGetters } from 'vuex'
import pushDataLayer from '~/mixins/gtm/push-data-layer'

export default {
  data() {
    return {
      growthBook: null,
      evaluatedExperiment: undefined,
    }
  },
  mixins: [pushDataLayer],
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  methods: {
    sendExperimentToGtm(experiment, result) {
      this.pushDataLayer({
        event: 'experiment_viewed',
        eventCategory: 'A/B Test',
        eventAction: 'Experiment Viewed',
        eventValue: {
          event_label: experiment.key,
          value: result.variationId,
        },
      })
    },
    async initializeGrowthBook(featureName) {
      try {
        const growthBook = await this.initGrowthBook()

        if (!growthBook) {
          // eslint-disable-next-line no-console
          console.warn(
            'GrowthBook failed to initialize. Feature flags and experiments not active.'
          )
          return
        }

        growthBook.setAttributes({
          id: this.userInfo.loggedIn
            ? this.userInfo.id
            : this.userInfo.internal_id,
        })

        growthBook.setTrackingCallback((experiment, result) => {
          this.sendExperimentToGtm(experiment, result)
        })

        const evaluatedFeatureName = growthBook.getFeatureValue(featureName, '')

        if (typeof evaluatedFeatureName !== 'undefined') {
          this.evaluatedExperiment = evaluatedFeatureName
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Unknown Error', error)
      }
    },
  },
}
