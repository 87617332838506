// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icons/header-menu/arrow-right.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-ce7ff854]:root{--light_text_color:#666}.category-tree__item[data-v-ce7ff854]{display:flex;align-items:center}.category-tree__item-arrow[data-v-ce7ff854]{margin-left:5px;margin-right:5px;width:5px;height:7px;color:#555;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50%/contain no-repeat}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
