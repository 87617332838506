<!-- eslint-disable no-console -->
<template>
  <div class="redirect-icons-row">
    <div
      ref="itemsContainer"
      class="redirect-icons-row__items"
      :class="{ 'justify-start': hasOverflow, 'justify-center': !hasOverflow }"
    >
      <RedirectIconsItem
        v-for="(item, index) in items"
        :key="index"
        :title="item.title"
        :image="item.image"
        :link="item.link"
        class="redirect-icons-row__item"
      />
    </div>
  </div>
</template>

<script>
import RedirectIconsItem from '@/components/home/RedirectIconsItem.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'RedirectIconsRow',
  components: {
    RedirectIconsItem,
  },
  props: {
    providedItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hasOverflow: false,
    }
  },
  computed: {
    items() {
      if (this.providedItems.length) return this.providedItems
      return [
        {
          title: this.$t('redirect_icons_row.fresh-bottle'),
          image:
            'https://ik.imagekit.io/gocase/engines/qr-img/75126206355636121740514993383.png?tr=w-72',
          link:
            '/garrafas-termicas-ebook/?product_filter=garrafas-termicas-ebook&page=1&sorting=favorites&new=1',
        },
        {
          title: this.$t('redirect_icons_row.case'),
          image:
            'https://ik.imagekit.io/gocase/engines/qr-img/81324230983295251740514918188.png?tr=w-72',
          link: '/capinha-para-celular',
        },
        {
          title: this.$t('redirect_icons_row.tote-daily'),
          image:
            'https://ik.imagekit.io/gocase/engines/qr-img/88421757865135361740515011375.png?tr=w-72',
          link: '/tote-bag',
        },
        {
          title: this.$t('redirect_icons_row.urban-bottle'),
          image:
            'https://ik.imagekit.io/gocase/engines/qr-img/81902579289859131740515096764.png?tr=w-72',
          link: '/garrafas-urban-ebook',
        },
        {
          title: this.$t('redirect_icons_row.joy-bag'),
          image:
            'https://ik.imagekit.io/gocase/engines/qr-img/237983936319315251740515267913.png?tr=w-72',
          link: '/gobolsasjoy',
        },
        {
          title: this.$t('redirect_icons_row.life-cup'),
          image:
            'https://ik.imagekit.io/gocase/engines/qr-img/071275847641116161740515032485.png?tr=w-72',
          link: '/copo-life',
        },
        {
          title: this.$t('redirect_icons_row.move-bag'),
          image:
            'https://ik.imagekit.io/gocase/engines/qr-img/44196997758174761741609181527.png?tr=w-72',
          link: '/bolsamoove',
        },
        {
          title: this.$t('redirect_icons_row.suitcase'),
          image:
            'https://ik.imagekit.io/gocase/engines/qr-img/482044905088348631741609127381.png?tr=w-72',
          link: '/mala-de-bordo',
        },
        {
          title: this.$t('redirect_icons_row.mini-bottle'),
          image:
            'https://ik.imagekit.io/gocase/engines/qr-img/7819993497011591740515055558.png?tr=w-72',
          link:
            '/garrafa-mini/?product_filter=garrafa-mini&page=1&sorting=favorites&new=1',
        },
      ]
    },
  },
  mounted() {
    this.debouncedCheckOverflow = debounce(this.checkOverflow, 200)
    window.addEventListener('resize', this.debouncedCheckOverflow)
    this.checkOverflow()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedCheckOverflow)
  },
  methods: {
    checkOverflow() {
      const container = this.$refs.itemsContainer
      this.hasOverflow = container.scrollWidth > container.clientWidth
    },
  },
}
</script>

<style lang="scss" scoped>
.redirect-icons-row {
  background-color: #fff;
  padding: 15px;

  &__items {
    display: flex;

    &.justify-start {
      justify-content: flex-start;
    }
    &.justify-center {
      justify-content: center;
    }

    @media (max-width: $v4_desktop) {
      overflow-x: auto;
      overflow-y: hidden;

      -webkit-overflow-scrolling: touch;
    }
  }

  &__item {
    padding: 4px 0px;
  }
}
</style>
