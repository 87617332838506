<template>
  <div
    :class="[
      'skeleton-categories',
      { 'skeleton-categories--mobile': $device && $device.isMobile },
    ]"
  >
    <div
      v-for="index in skeletonCount"
      :key="index"
      class="skeleton-categories-item"
    >
      <span class="skeleton-placeholder">
        <three-dots-loading />
      </span>
    </div>
  </div>
</template>

<script>
import ThreeDotsLoading from '~/components/loadings/three-dots/ThreeDotsLoading.vue'

export default {
  name: 'RowItemsSkeletonLoading',
  components: {
    ThreeDotsLoading,
  },
  props: {
    skeletonCount: {
      type: Number,
      default: 9,
    },
  },
}
</script>

<style scope lang="scss">
.skeleton-categories {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 -15px;
  padding: 0 15px;
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.skeleton-categories--mobile {
  overflow-x: auto;
}

.skeleton-categories::-webkit-scrollbar {
  display: none;
}

.skeleton-categories-item {
  display: inline-block;
  margin-right: 10px;
}

.skeleton-placeholder {
  width: 130px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $skeleton_color;
  padding: 5px 10px;
  border-radius: 3px;
}
</style>
