<template>
  <a :href="link" rel="noopener noreferrer" class="redirect-icons-item">
    <img
      :src="image"
      :alt="title"
      class="redirect-icons-item__image"
      loading="lazy"
    />
    <p class="redirect-icons-item__title">
      {{ title }}
    </p>
  </a>
</template>

<script>
export default {
  name: 'RedirectIconsItem',
  props: {
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.redirect-icons-item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.3s;
  justify-content: space-between;
  min-width: 120px;

  &:hover {
    transform: scale(1.03);
  }

  &__image {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__title {
    text-align: center;
    color: $v4_color_text;
    font-size: 13px;
    white-space: nowrap;
    white-space: normal;
    word-wrap: break-word;
    margin-bottom: 0px;
  }
}
</style>
